import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'

import AccessView from '../views/AccessView'
import BrowserPage from '../views/BrowserPage'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'access',
        component: AccessView,
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/test.vue'),
    },
    {
        path: '/faceDetection',
        name: 'faceDetection',
        component: () => import(/* webpackChunkName: "FaceDetection" */ '../views/FaceDetection'),
    },
    {
        path: '/faceComparison',
        name: 'faceComparison',
        component: () => import(/* webpackChunkName: "FaceComparison" */ '../views/FaceComparison'),
    },
    {
        path: '/faceVerify',
        name: 'faceVerify',
        component: () => import(/* webpackChunkName: "FaceVerify" */ '../views/FaceVerify'),
    },
    {
        path: '/faceLiveness',
        name: 'faceLiveness',
        component: () => import(/* webpackChunkName: "FaceLiveness" */ '../views/FaceLiveness'),
    },
    {
        path: '/faceResidentCamera',
        name: 'faceResidentCamera',
        component: () =>
            import(/* webpackChunkName: "FaceResidentCamera" */ '../views/FaceResidentCamera'),
    },
    {
        path: '/browserPage',
        name: 'browserPage',
        component: BrowserPage,
    },
    {
        path: '/svgView',
        name: 'svgView',
        component: () => import(/* webpackChunkName: "svgView" */ '../components/SvgView'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
