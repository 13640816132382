/**
 * 将对象添加当作参数拼接到URL上面
 * @param baseUrl 需要拼接的url
 * @param obj 参数对象
 * @returns {string} 拼接后的对象
 * 例子:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: Record<string, any>): string {
    let parameters = ''
    let url = ''
    Object.keys(obj).forEach(item => {
        parameters += item + '=' + encodeURIComponent(obj[item]) + '&'
    })

    parameters = parameters.replace(/&$/, '')
    if (/\?$/.test(baseUrl)) {
        url = baseUrl + parameters
    } else {
        url = baseUrl.replace(/\/?$/, '?') + parameters
    }
    return url
}
