import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export interface QueryParamsType {
    'x-zahb-paas-auth-token': string //鉴权token
    userId: string //用户ID
    // userName?: string //用户名
    rootOrgId: string //企业ID
    requestId?: string //请求ID  跳转浏览器 查询认证结果使用
    isBrowser?: string // 1 浏览器环境
    extensions?: string //扩展字段
    faceModule: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 //识别形式  1 人脸检测 2 人脸比对 3 图片活体检测 4 视频活体检测 5 常驻摄像头 6 身份证 + 人脸对比 7 身份证 + 图片活体检测 8 身份证 + 视频活体检测
    shootMode: 1 | 2 // 拍摄方式 1 api摄像头模式 2 直接唤起原生相机
    referencePicture: string //识别图片URL
    faceScore: number //人脸比对阈值
    faceLiveness: number //活体检测阈值
    arouseHandle: 1 | 2 | 3 //摄像头调取失败处理  1 不做任何处理 直接通知 2 唤起原生相机 3 跳转浏览器
    targetOrigin: string //通信域名（项目域名）
    tryAgain: 1 | 2 //识别失败是否显示再试一次按钮 1 是 2否
    showCancel: 1 | 2 //识别失败是否显示取消按钮 1 是 2否
    cancelText?: string //取消按钮的文本内容
    randomCapturing: 1 | 2 //常驻摄像头  是否随机抓拍 时间间隔内随机抓拍一张 1 是 2否
    capturingTimeout: number //随机抓拍 时间间隔
    capturingRecognition: 1 | 2 //抓拍图片是否进行人脸比对 1 是 2 否
    firstCapturing: 1 | 2 //开启摄像头首次抓拍 1 是 2否
}

/**
 * 验证 url 参数
 * @returns MSG：错误消息 query：参数
 */

export default function () {
    const route = useRoute()
    const isActive = ref(false)
    const MSG = ref('')
    const query: Partial<QueryParamsType> = route.query

    onMounted(() => {
        if (query.isBrowser != '1' && window.self === window.top) {
            MSG.value = '请使用iframe嵌入'
        } else {
            validate()
        }
    })

    // 参数校验
    const validate = () => {
        if (!query['x-zahb-paas-auth-token']) {
            MSG.value = 'x-zahb-paas-auth-token必填'
            return
        }
        if (!query.userId) {
            MSG.value = 'userId必填'
            return
        }
        if (!query.rootOrgId) {
            MSG.value = 'rootOrgId必填'
            return
        }
        if (query.faceModule && ![1, 2, 3, 4, 5, 6, 7, 8].includes(Number(query.faceModule))) {
            MSG.value = 'faceModule取值 1,2,3,4,5,6,7,8'
            return
        }
        if (
            (query.faceModule == 2 || query.faceModule == 3 || query.faceModule == 4) &&
            !query.referencePicture
        ) {
            MSG.value = 'referencePicture必填'
            return
        }
        // if (
        //     (query.faceModule == 6 || query.faceModule == 7 || query.faceModule == 8) &&
        //     !query.userName
        // ) {
        //     MSG.value = 'userName必填'
        //     return
        // }

        if (!query.targetOrigin) {
            MSG.value = 'targetOrigin必填'
            return
        }

        // 默认值
        !query.faceModule && (query.faceModule = 2)
        !query.shootMode && (query.shootMode = 1)
        !query.faceScore && (query.faceScore = 0.5)
        !query.faceLiveness && (query.faceLiveness = 0.5)
        !query.arouseHandle && (query.arouseHandle = 2)
        !query.capturingTimeout && (query.capturingTimeout = 5)
        !query.randomCapturing && (query.randomCapturing = 2)
        !query.capturingRecognition && (query.capturingRecognition = 1)
        !query.firstCapturing && (query.firstCapturing = 1)
        !query.tryAgain && (query.tryAgain = 2)
        !query.showCancel && (query.showCancel = 2)
        !query.cancelText && (query.cancelText = '关闭')

        if (query.faceModule == 5 && query.capturingRecognition == 1 && !query.referencePicture) {
            MSG.value = 'capturingRecognition ==1 referencePicture必填'
            return
        }

        isActive.value = true
        return true
    }

    return {
        isActive,
        MSG,
        query,
        validate,
    }
}
