const VUE_APP_MODE = process.env.VUE_APP_MODE
let baseURL: string
if (VUE_APP_MODE == 'development') {
    baseURL = 'https://testpaas.qyaqdx.com/' // 本地开发
} else {
    //pre 预生产
    // prod 生产
    switch (VUE_APP_MODE) {
        case 'develop': //测试
            baseURL = 'https://testpaas.qyaqdx.com/'
            break
        case 'pre': // 预生产
            baseURL = 'https://paas.qyaqdx.com/'
            break
        case 'prod': // 生产
            baseURL = 'https://api.qianuni.com/'
            break
        default:
            baseURL = 'https://testpaas.qyaqdx.com/'
            break
    }
}

export default baseURL
